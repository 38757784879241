<template>
  <section id="Counseling">
           <v-sheet
    :height="$vuetify.breakpoint.mdAndUp ? '50vh' : '75vh'"
    color="primary"
    tile
  >
    <!-- <v-img
      :key="image"
      :src="image"
      gradient="to top, rgba(0,0,0, .10), rgba(0,0,0, .50)"
      height="100%"
      position="top"
    > -->
      <v-container
        fill-height
        fluid
        class="py-0"
      >
        <!-- <v-row class="fill-height"> -->
        <v-row>
          <v-col justify="center" align="center" class="ml-4">
            <h1
              class="mb-2 white--text big-text text-uppercase">Consulting</h1>
              <v-divider class="mb-4" color="white" width="200"> </v-divider>
            <p
              class="white--text mb-0" style="font-size: 1.2em">With over 40 years of full-time professional counseling experience, we have worked with a <br> wide range of organizations, ministries and other business entities.<br>
We can serve you to help strengthen the work your organization provides.</p>
          </v-col>
        </v-row>
      </v-container>
    <!-- </v-img> -->
  </v-sheet>
    <v-container fluid>
      <v-row>
      <!-- <v-col class="pa-0" cols="6">
          <v-img
              src="/static/asian-couple-argument.jpg"
              gradient="to top, rgba(0,0,0, .10), rgba(0,0,0, .10)"
            />
      </v-col>
      <v-col class="pa-0" cols="6">
            <v-img
              src="/static/asian-couple-cooking-in-the-kitchen.jpg"
              gradient="to top, rgba(0,0,0, .10), rgba(0,0,0, .10)"
            />
      </v-col> -->
        <!-- <v-col
          cols="12"
          align="center"
          class="mt-4"
        >
            <h1 class="big-text text-uppercase">Consulting</h1>

            <p>
             With over 30 years of full time professional counseling experience.  I have worked with a wide range of issues.  I only work with adults, and do individual, couples and adult family counseling.
            </p>
        </v-col> -->
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12" offset-md="2">
          <v-sheet>
            <v-row align="center">
              <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                <v-img style="border-radius: 10px;" src='/static/stressed-businessman.png' alt="stressed businessman"/>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 9">
            <v-row>
               <v-col align="center" cols="12">
                  <h2>Business Mental Health Consulting</h2>
               <v-divider class="mt-3" ></v-divider>
               </v-col>
               <v-col :class="$vuetify.breakpoint.mdAndUp ? 'mx-6' : ''" cols="11">
                  <p>Your employees often have to deal with Traumatic events, or your business often needs assistance training employees for a variety of challenges. We can help with that.</p>
               </v-col>
               <v-col :class="$vuetify.breakpoint.mdAndUp ? 'mx-6' : ''" cols="11">
                      <h3>Areas of specialty here include:</h3>
                      <ul>
                        <li v-for="(item, index) in areasofspecialtybusiness" :key="index">{{item}}</li>
                    </ul>
               </v-col>
               <v-col :class="$vuetify.breakpoint.mdAndUp ? 'mx-6' : ''" cols="11">
                      <h3>Traumatic Events</h3>
                      <p>Sadly, many businesses today deal with Traumatic events like work accidents, suicide, injuries, shootings etc. As a counselor Doug can help employees move on from these traumatic events </p>
               </v-col>
            </v-row>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
         <v-col :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12" offset-md="2">
           <v-sheet>
             <v-row align="center">
              <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                <v-img style="border-radius: 10px;" src='/static/dougspeaking.png' alt="doug feil teaching"/>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 9">
            <v-row>
               <v-col align="center" cols="12">
                  <h2>Pastors and Missionary Consulting</h2>
               <v-divider class="mt-3" ></v-divider>
               </v-col>
                <v-col :class="$vuetify.breakpoint.mdAndUp ? 'mx-6' : ''" cols="11">
                      <h3>Areas of specialty here include:</h3>
                      <ul>
                        <li v-for="(item, index) in areasofspecialtysingels" :key="index">{{item}}</li>
                    </ul>
               </v-col>
               <v-col :class="$vuetify.breakpoint.mdAndUp ? 'mx-6' : ''" cols="11">
                      <h3>Missionary and Pastors</h3>
                      <p>Having been involved in Christian ministry for over 40 years, areas of specialty include pastors, missionaries, Christian leaders, and working with Christian organizations.</p>       
                        <v-btn
                        to="ministry"
                        class="pl-0"
                        color="primary"
                        text
                      >Learn more</v-btn>
               </v-col>
            </v-row>
               </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="12" offset-md="2">
          <v-row>
            <v-col cols="12">
              <h2 style="color: grey;" class="text-uppercase">Related Blog Articles</h2>
            </v-col>
            <v-col v-for="(article, index) in Articles" :key="index" cols="2">
              <v-sheet color="white" elevation="1" class="rounded-xl">
                <v-row>
                  <v-col align="center" cols="12" class="mt-0 pt-0">
                  <v-img style="border-top-left-radius: 25px; border-top-right-radius: 25px;" :src="article.img"></v-img>
                  </v-col>
                  <v-col align="center" cols="12" class="pt-0">
                      <h3>{{article.title}}</h3>
                  <v-divider class="mt-3" ></v-divider>
                  </v-col>
                  <v-col cols="12" class="ml-2 px-8">
                      <p>{{article.description}}</p>
                      <v-btn class="pl-0" color="primary" :onclick="`location.href='${article.url}';`" text>Read More</v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <AccentBar v-if="$vuetify.breakpoint.mdAndUp"/>
    <ContactBar />
  </section>
</template>

<script>
import blogArticles from '../blogarticles.json';
  export default {
    metaInfo() {
        return {
            title: 'Feil Consulting Services',
            titleTemplate: 'Consulting | Feil Counseling and Consulting',
            htmlAttrs: {
              lang: 'en-US'
            },
            meta: [
              { charset: 'utf-8' },
              { name: 'description', content: 'I can serve you to help strengthen the work your organization provides' },
              {property: 'og:site_name', content: 'Feil Counseling and Consulting'},
              {property: 'og:image', content: '/static/feil_logo_vertical.png'},
              {property: 'twitter:image:src', content: '/static/feil_logo_vertical.png'}
            ]
        };
    },
    components: {
      Heading: () => import('@/components/Heading'),
      Testimonial: () => import('@/components/Testimonial'),
      GetAQuote: () => import('@/components/GetAQuote'),
      ContactBar: () => import('@/components/ContactBar'),
      MidImage: () => import('@/components/MidImage'),
      AccentBar: () => import('@/components/AccentBar')
    },

    data: () => ({
      testimonial: {
        blurb: 'With Alpha Construction managing all of our customer-facing services and ASCC, helping us monitor and understand building performance, Abocado is in great shape to create and release new products.',
        person: 'Jay Oakrson',
        title: 'CEO Abocado'
      },
      areasofspecialtybusiness: ["Mediation", "Supervision training", "Post traumatic event counseling", "Speaking", "Team Building", "Strengthening self-care", "Critical incident debriefing"],
      areasofspecialtysingels: ["Team building", "Member care", "Burnout", "Crisis and trauma care", "Strengthening elders and pastor relationships", "Self-care", "Training in specific areas of need."]
    }),
    computed:{
      Articles(){
        let articles = blogArticles.filter((item)=>{
          return item.page == 'consulting'
        })

        return articles;
      }
    }
  }
</script>
<style scoped>
.big-text {
    font-size: 3em;
}
.midbar-text {
    font-size: 1.5em;
}
</style>
